import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

// Import sections
import ProductsHeroSection from "../sections/ProductsHeroSection";
import ProductsGridSection from "../sections/ProductsGridSection";

// Import data
import { useProducts } from "../data/products";

const ProductsPage = () => {
  const { t, i18n } = useTranslation();
  const isFrench = i18n.language === "fr";

  // Get translated products using the new hook
  const products = useProducts();

  return (
    <>
      <Helmet>
        <title>
          {isFrench
            ? "Produits | Northtouch Canada Inc. - Solutions de Fabrication Électronique"
            : "Products | Northtouch Canada Inc. - Electronics Manufacturing Solutions"}
        </title>
        <meta
          name="description"
          content={
            isFrench
              ? "Découvrez notre gamme complète de solutions de fabrication électronique, d'équipements de test et de machines d'assemblage de pointe."
              : "Explore our comprehensive range of electronics manufacturing solutions, testing equipment, and cutting-edge assembly machines."
          }
        />
      </Helmet>

      <ProductsHeroSection />
      <ProductsGridSection products={products} />
    </>
  );
};

export default ProductsPage;
