import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import ManufacturerCard from "../components/shared/ManufacturerCard";

const ManufacturersGridSection = ({
  filteredManufacturers,
  manufacturersTotal,
  activeFilter,
  setActiveFilter,
}) => {
  const { t, i18n } = useTranslation();
  const isFrench = i18n.language === "fr";

  return (
    <section className="py-20 bg-white relative">
      <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      <div className="container mx-auto px-6 relative">
        {filteredManufacturers.length > 0 ? (
          <>
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="flex flex-wrap justify-between items-end">
                <div>
                  <h2 className="text-4xl font-bold mb-3 text-gray-900">
                    {isFrench ? (
                      <>
                        Nos{" "}
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-indigo-600">
                          Fabricants
                        </span>
                      </>
                    ) : (
                      <>
                        Our{" "}
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-indigo-600">
                          Manufacturers
                        </span>
                      </>
                    )}
                  </h2>
                  <p className="text-gray-600 text-lg max-w-2xl">
                    {activeFilter !== t("categories.all")
                      ? isFrench
                        ? `Solutions de ${activeFilter} pour vos besoins de fabrication électronique`
                        : `${activeFilter} solutions for your electronics manufacturing needs`
                      : isFrench
                      ? "Solutions de classe mondiale pour vos besoins de fabrication électronique"
                      : "World-class solutions for your electronics manufacturing needs"}
                  </p>
                </div>

                {filteredManufacturers.length > 0 && (
                  <div className="text-gray-500 mt-4 md:mt-0 bg-gray-100 py-2 px-4 rounded-full text-sm">
                    {isFrench
                      ? `Affichage de ${filteredManufacturers.length} sur ${manufacturersTotal} partenaires`
                      : `Showing ${filteredManufacturers.length} of ${manufacturersTotal} partners`}
                  </div>
                )}
              </div>
            </motion.div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {filteredManufacturers.map((manufacturer, index) => (
                <div key={manufacturer.id} className="h-full">
                  <ManufacturerCard
                    logo={manufacturer.logo}
                    name={manufacturer.name}
                    description={manufacturer.description}
                    website={manufacturer.website}
                    category={manufacturer.category}
                    index={index}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <motion.div
            className="py-32 text-center bg-gray-50 rounded-3xl border border-gray-100 shadow-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="inline-flex items-center justify-center bg-purple-100 text-purple-600 w-20 h-20 rounded-full mb-6">
              <svg
                className="w-10 h-10"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              {isFrench ? "Aucun fabricant trouvé" : "No manufacturers found"}
            </h3>
            <p className="text-gray-600 mb-8 max-w-md mx-auto">
              {isFrench
                ? "Nous n'avons trouvé aucun fabricant correspondant à vos critères de recherche."
                : "We couldn't find any manufacturers matching your search criteria."}
            </p>
            <button
              onClick={() => {
                setActiveFilter(t("categories.all"));
              }}
              className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-lg font-medium hover:shadow-lg transition-all duration-300 transform hover:scale-105"
            >
              {isFrench ? "Voir Tous les Fabricants" : "View All Manufacturers"}
            </button>
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default ManufacturersGridSection;
