import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Breadcrumbs from "../components/Breadcrumbs";

const MainLayout = () => {
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const { t, i18n } = useTranslation();

  // Check if current language is French from URL
  const isFrench = location.pathname.startsWith("/fr");

  // Set language based on URL
  useEffect(() => {
    if (isFrench && i18n.language !== "fr") {
      i18n.changeLanguage("fr");
    } else if (!isFrench && i18n.language !== "en") {
      i18n.changeLanguage("en");
    }
  }, [isFrench, i18n]);

  // Handle page transitions
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoaded(true);
  }, [location.pathname]);

  // Page transition variants
  const pageVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.5,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3,
      },
    },
  };

  // Dynamic titles based on current route
  const getTitle = () => {
    const path = isFrench
      ? location.pathname.replace("/fr", "")
      : location.pathname;

    if (path === "/" || path === "") {
      return isFrench
        ? "Northtouch Canada Inc. - Représentant de premier plan de fabricants d'électronique au Canada"
        : "Northtouch Canada Inc. - Leading Electronics Manufacturer's Representative in Canada";
    }

    if (path === "/manufacturers") {
      return isFrench
        ? "Nos Fabricants et Partenaires | Northtouch Canada Inc."
        : "Our Manufacturers & Partners | Northtouch Canada Inc.";
    }

    if (path === "/products") {
      return isFrench
        ? "Solutions et Produits de Fabrication Électronique | Northtouch Canada Inc."
        : "Electronic Manufacturing Solutions & Products | Northtouch Canada Inc.";
    }

    if (path === "/news") {
      return isFrench
        ? "Actualités et Mises à Jour de l'Industrie | Northtouch Canada Inc."
        : "Industry News & Updates | Northtouch Canada Inc.";
    }

    if (path === "/contact") {
      return isFrench
        ? "Contactez-Nous pour des Solutions de Fabrication Électronique | Northtouch Canada Inc."
        : "Contact Us for Electronic Manufacturing Solutions | Northtouch Canada Inc.";
    }

    return isFrench
      ? "Northtouch Canada Inc. - Solutions de Fabrication Électronique"
      : "Northtouch Canada Inc. - Electronics Manufacturing Solutions";
  };

  // Get the canonical path
  const getCanonicalPath = () => {
    return `https://northtouch.com${location.pathname}`;
  };

  // Get alternate language URL
  const getAlternateLanguageUrl = () => {
    if (isFrench) {
      return `https://northtouch.com${location.pathname.replace("/fr", "")}`;
    } else {
      return `https://northtouch.com/fr${location.pathname}`;
    }
  };

  // Get page description based on language
  const getDescription = () => {
    return isFrench
      ? "Northtouch Canada Inc. est une entreprise représentante de fabricants d'électronique de premier plan spécialisée dans les machines d'assemblage, les tests, la programmation, la vérification et les solutions de diagnostic pour le marché canadien."
      : "Northtouch Canada Inc. is a leading electronics manufacturer's representatives company specializing in assembly machines, test, programming, verification and diagnostic solutions for the Canadian market.";
  };

  // Get OG description based on language
  const getOgDescription = () => {
    return isFrench
      ? "Représentant de fabricants d'électronique au Canada spécialisé dans les machines d'assemblage, les tests et les solutions de diagnostic."
      : "Electronics manufacturer's representative in Canada specializing in assembly machines, test, and diagnostic solutions.";
  };

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getOgDescription()} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={getCanonicalPath()} />
        <link rel="canonical" href={getCanonicalPath()} />
        <link
          rel="alternate"
          href={getAlternateLanguageUrl()}
          hrefLang={isFrench ? "en" : "fr"}
        />
      </Helmet>

      <Header isFrench={isFrench} />

      <main className="flex-grow">
        <AnimatePresence mode="wait">
          {isLoaded && (
            <motion.div
              key={location.key}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={pageVariants}
              className="min-h-[50vh]"
            >
              <Breadcrumbs isFrench={isFrench} />
              <Outlet />
            </motion.div>
          )}
        </AnimatePresence>
      </main>

      <Footer isFrench={isFrench} />
      <ScrollToTopButton />
    </>
  );
};

export default MainLayout;
