import React, { useState, useEffect, useMemo, useCallback } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import ProductCard from "../components/shared/ProductCard";
import CategoryFilter from "../components/shared/CategoryFilter";
import { useProductCategories } from "../data/products";

const ProductsGridSection = ({ products, isFrench }) => {
  const { t, i18n } = useTranslation();
  const [activeFilter, setActiveFilter] = useState(t("productCategories.all"));
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Get categories using the product categories hook
  const categories = useProductCategories();

  // Filter products based on active category - memoized with useCallback
  const filterProducts = useCallback(() => {
    if (activeFilter === t("productCategories.all")) {
      return products;
    }

    return products.filter((product) => product.category === activeFilter);
  }, [activeFilter, products, t]);

  // Apply filtering when dependencies change
  useEffect(() => {
    setFilteredProducts(filterProducts());
  }, [filterProducts]);

  // Reset active filter when language changes
  useEffect(() => {
    setActiveFilter(t("productCategories.all"));
  }, [i18n.language, t]);

  // Handle category click
  const handleCategoryClick = useCallback((category) => {
    setActiveFilter(category);
  }, []);

  return (
    <section className="py-12 md:py-16 bg-white relative">
      <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      <div className="container mx-auto px-6 relative">
        {/* Category Filters */}
        <div className="flex flex-wrap gap-3 justify-center mb-12">
          {categories.map((category) => (
            <CategoryFilter
              key={category}
              category={category}
              active={category === activeFilter}
              onClick={() => handleCategoryClick(category)}
            />
          ))}
        </div>

        {/* Products Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredProducts.map((product, index) => (
            <ProductCard
              key={product.id}
              logo={product.logo}
              name={product.name}
              description={product.description}
              website={product.website}
              category={product.category}
              index={index}
              isFrench={isFrench}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductsGridSection;
