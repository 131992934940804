// Import manufacturer logos
import acculogicLogo from "../assets/images/Acculogic-logo.png";
import aegisLogo from "../assets/images/AEGIS-logo.png";
import asmptLogo from "../assets/images/asmpt-logo.png";
import iblLogo from "../assets/images/ibl-logo.svg";
import jtagLogo from "../assets/images/JTAG-logo.png";
import kicLogo from "../assets/images/KIC-logo.png";
import kurtzeErsaLogo from "../assets/images/kurtz-ersa.svg";
import komaxLogo from "../assets/images/Logo_Komax.png";
import adtLogo from "../assets/images/adt_logo.png";
import mycronicLogo from "../assets/images/mycronic.png";

// Placeholder images - replace with actual images if available
import flyingProberImage from "../assets/images/production_line-hero.png";
import solderingEquipmentImage from "../assets/images/production_line-hero.png";
import { useTranslation } from "react-i18next";

/**
 * Get manufacturer data with translations from i18n
 * @returns {Array} - Array of manufacturer objects
 */
export const useManufacturers = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return [
    {
      id: 1,
      name: t("manufacturers.acculogic.name"),
      logo: acculogicLogo,
      description: t("manufacturers.acculogic.description"),
      website: "https://acculogic.com/",
      category: t(`categories.${t("manufacturers.acculogic.category")}`),
      categoryKey: "testing",
      featured: true,
      image: flyingProberImage,
    },
    {
      id: 2,
      name: t("manufacturers.aegisSoftware.name"),
      logo: aegisLogo,
      description: t("manufacturers.aegisSoftware.description"),
      website: "https://www.aiscorp.com/",
      category: t(`categories.${t("manufacturers.aegisSoftware.category")}`),
      categoryKey: "software",
    },
    {
      id: 3,
      name: t("manufacturers.asmpt.name"),
      logo: asmptLogo,
      description: t("manufacturers.asmpt.description"),
      website: "https://semi.asmpt.com/en/",
      category: t(`categories.${t("manufacturers.asmpt.category")}`),
      categoryKey: "assembly",
    },
    {
      id: 4,
      name: t("manufacturers.ibl.name"),
      logo: iblLogo,
      description: t("manufacturers.ibl.description"),
      website: "https://ibltech.com/en/",
      category: t(`categories.${t("manufacturers.ibl.category")}`),
      categoryKey: "soldering",
      featured: true,
      image: solderingEquipmentImage,
    },
    {
      id: 5,
      name: t("manufacturers.jtagTech.name"),
      logo: jtagLogo,
      description: t("manufacturers.jtagTech.description"),
      website: "https://jtag.northtouch.com/",
      category: t(`categories.${t("manufacturers.jtagTech.category")}`),
      categoryKey: "programming",
    },
    {
      id: 6,
      name: t("manufacturers.kicThermal.name"),
      logo: kicLogo,
      description: t("manufacturers.kicThermal.description"),
      website: "https://kicthermal.com/",
      category: t(`categories.${t("manufacturers.kicThermal.category")}`),
      categoryKey: "thermal",
    },
    {
      id: 7,
      name: t("manufacturers.komax.name"),
      logo: komaxLogo,
      description: t("manufacturers.komax.description"),
      website: "https://www.komaxgroup.com/",
      category: t(`categories.${t("manufacturers.komax.category")}`),
      categoryKey: "automation",
    },
    {
      id: 8,
      name: t("manufacturers.ersa.name"),
      logo: kurtzeErsaLogo,
      description: t("manufacturers.ersa.description"),
      website: "https://www.kurtzersa.com/homepage.html",
      category: t(`categories.${t("manufacturers.ersa.category")}`),
      categoryKey: "soldering",
    },
    {
      id: 9,
      name: t("manufacturers.adt.name"),
      logo: adtLogo,
      description: t("manufacturers.adt.description"),
      website: "https://www.adt-dicing.com/",
      category: t(`categories.${t("manufacturers.adt.category")}`),
      categoryKey: "testing",
    },
    {
      id: 10,
      name: t("manufacturers.mycronic.name"),
      logo: mycronicLogo,
      description: t("manufacturers.mycronic.description"),
      website: "https://www.mycronic.com/",
      category: t(`categories.${t("manufacturers.mycronic.category")}`),
      categoryKey: "assembly",
    },
  ];
};

/**
 * Get all unique categories
 * @returns {Array} - Array of category strings
 */
export const useCategories = () => {
  const { t } = useTranslation();
  const manufacturers = useManufacturers();

  const allCategories = [
    t("categories.all"),
    ...new Set(manufacturers.map((m) => m.category)),
  ];

  return allCategories;
};
