import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

// Import data with new hooks
import { useManufacturers, useCategories } from "../data/manufacturers";

// Import sections
import ManufacturersHeroSection from "../sections/ManufacturersHeroSection";
import ManufacturersCategoriesSection from "../sections/ManufacturersCategoriesSection";
import ManufacturersGridSection from "../sections/ManufacturersGridSection";
import ManufacturersCTASection from "../sections/ManufacturersCTASection";

const ManufacturersPage = () => {
  const { t, i18n } = useTranslation();
  const isFrench = i18n.language === "fr";

  const [activeFilter, setActiveFilter] = useState(t("categories.all"));
  const [filteredManufacturers, setFilteredManufacturers] = useState([]);

  // Get manufacturers data using the new hook
  const manufacturers = useManufacturers();

  // Get categories using the new hook
  const categories = useCategories();

  // Filter manufacturers based on category with memoization
  const filterManufacturers = useCallback(() => {
    if (activeFilter === t("categories.all")) {
      return manufacturers;
    }

    return manufacturers.filter((m) => m.category === activeFilter);
  }, [activeFilter, manufacturers, t]);

  // Apply filtering when dependencies change
  useEffect(() => {
    setFilteredManufacturers(filterManufacturers());
  }, [filterManufacturers]);

  // Reset active filter when language changes
  useEffect(() => {
    setActiveFilter(t("categories.all"));
  }, [i18n.language, t]);

  // Handle filter click with memoization
  const handleFilterClick = useCallback((category) => {
    setActiveFilter(category);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {isFrench
            ? "Nos Fabricants | Northtouch Canada Inc."
            : "Our Manufacturers | Northtouch Canada Inc."}
        </title>
        <meta
          name="description"
          content={
            isFrench
              ? "Northtouch Canada représente des fabricants de classe mondiale dans l'industrie électronique offrant des machines d'assemblage, de test, de programmation, de vérification et des solutions de diagnostic."
              : "Northtouch Canada represents world-class manufacturers in the electronics industry offering assembly machines, test, programming, verification and diagnostic solutions."
          }
        />
      </Helmet>

      <ManufacturersHeroSection manufacturersCount={manufacturers.length} />

      <ManufacturersCategoriesSection
        categories={categories}
        activeFilter={activeFilter}
        onFilterClick={handleFilterClick}
      />

      <ManufacturersGridSection
        filteredManufacturers={filteredManufacturers}
        manufacturersTotal={manufacturers.length}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />

      <ManufacturersCTASection />
    </>
  );
};

export default ManufacturersPage;
