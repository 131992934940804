import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

/**
 * Custom hook to get language information
 * @returns {Object} - Object with language-related props
 */
export const useLanguage = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // Detect if current language is French from URL and i18n
  const isFrench = i18n.language === "fr";

  // Function to toggle language
  const toggleLanguage = () => {
    const newLang = isFrench ? "en" : "fr";
    i18n.changeLanguage(newLang);

    // Update URL to reflect language change
    const currentPath = location.pathname;
    let newPath;

    if (isFrench) {
      // Switch from French to English - remove /fr prefix
      newPath = currentPath.replace(/^\/fr/, "");
      if (newPath === "") newPath = "/"; // Handle root path
    } else {
      // Switch from English to French - add /fr prefix
      newPath = `/fr${currentPath === "/" ? "" : currentPath}`;
    }

    return newPath;
  };

  return {
    isFrench,
    currentLanguage: i18n.language,
    toggleLanguage,
    t,
  };
};
