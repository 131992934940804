// Import product logos
import acculogicLogo from "../assets/images/Acculogic-logo.png";
import aegisLogo from "../assets/images/AEGIS-logo.png";
import asmptLogo from "../assets/images/asmpt-logo.png";
import iblLogo from "../assets/images/ibl-logo.svg";
import jtagLogo from "../assets/images/JTAG-logo.png";
import kicLogo from "../assets/images/KIC-logo.png";
import kurtzeErsaLogo from "../assets/images/kurtz-ersa.svg";
import komaxLogo from "../assets/images/Logo_Komax.png";
import adtLogo from "../assets/images/adt_logo.png";
import mycronicLogo from "../assets/images/mycronic.png";
import { useTranslation } from "react-i18next";

/**
 * Get product data with translations from i18n
 * @returns {Array} - Array of product objects
 */
export const useProducts = () => {
  const { t } = useTranslation();

  return [
    // Manufacturing Equipment
    {
      id: 1,
      name: t("products.asmpt.name"),
      logo: asmptLogo,
      description: t("products.asmpt.description"),
      website: "https://semi.asmpt.com/en/",
      category: t("productCategories.manufacturingEquipment"),
      categoryKey: "manufacturingEquipment",
    },
    {
      id: 2,
      name: t("products.aegisSoftware.name"),
      logo: aegisLogo,
      description: t("products.aegisSoftware.description"),
      website: "https://www.aiscorp.com/",
      category: t("productCategories.manufacturingEquipment"),
      categoryKey: "manufacturingEquipment",
    },
    {
      id: 3,
      name: t("products.ibl.name"),
      logo: iblLogo,
      description: t("products.ibl.description"),
      website: "https://ibltech.com/en/",
      category: t("productCategories.manufacturingEquipment"),
      categoryKey: "manufacturingEquipment",
    },
    {
      id: 4,
      name: t("products.ersa.name"),
      logo: kurtzeErsaLogo,
      description: t("products.ersa.description"),
      website: "https://www.kurtzersa.com/homepage.html",
      category: t("productCategories.manufacturingEquipment"),
      categoryKey: "manufacturingEquipment",
    },
    {
      id: 5,
      name: t("products.kicThermal.name"),
      logo: kicLogo,
      description: t("products.kicThermal.description"),
      website: "https://kicthermal.com/",
      category: t("productCategories.manufacturingEquipment"),
      categoryKey: "manufacturingEquipment",
    },
    {
      id: 6,
      name: t("products.komax.name"),
      logo: komaxLogo,
      description: t("products.komax.description"),
      website: "https://www.komaxgroup.com/",
      category: t("productCategories.manufacturingEquipment"),
      categoryKey: "manufacturingEquipment",
    },
    {
      id: 10,
      name: t("products.mycronic.name"),
      logo: mycronicLogo,
      description: t("products.mycronic.description"),
      website: "https://www.mycronic.com/",
      category: t("productCategories.manufacturingEquipment"),
      categoryKey: "manufacturingEquipment",
    },

    // Programming and Testing
    {
      id: 7,
      name: t("products.acculogic.name"),
      logo: acculogicLogo,
      description: t("products.acculogic.description"),
      website: "https://acculogic.com/",
      category: t("productCategories.programmingAndTesting"),
      categoryKey: "programmingAndTesting",
    },
    {
      id: 8,
      name: t("products.jtagTech.name"),
      logo: jtagLogo,
      description: t("products.jtagTech.description"),
      website: "https://jtag.northtouch.com/",
      category: t("productCategories.programmingAndTesting"),
      categoryKey: "programmingAndTesting",
    },
    {
      id: 9,
      name: t("products.adt.name"),
      logo: adtLogo,
      description: t("products.adt.description"),
      website: "https://www.adt-dicing.com/",
      category: t("productCategories.programmingAndTesting"),
      categoryKey: "programmingAndTesting",
    },
  ];
};

/**
 * Get all unique product categories
 * @returns {Array} - Array of category strings
 */
export const useProductCategories = () => {
  const { t } = useTranslation();
  const products = useProducts();

  const uniqueCategories = new Set(
    products.map((product) => product.categoryKey)
  );
  return [
    t("productCategories.all"),
    ...Array.from(uniqueCategories).map((key) => t(`productCategories.${key}`)),
  ];
};
